import { create } from "zustand";
import { STEP_PAGES, ENV_CONFIG } from "./utils/Constants";

export const useProcessStore = create((set) => {
  return {
    selectedModel: ENV_CONFIG.DEFAULT_MODEL,
    setSelectedModel: (model) => {
      set((state) => {
        return { selectedModel: model };
      });
    },
    isLoading: false,
    currentStep: STEP_PAGES.IMAGE_UPLOADER,
    goToStep: (step) => set((state) => ({ currentStep: step })),
    setIsLoading: (loading) => set((state) => ({ isLoading: loading })),
    overwriteStore: false,
    setOverwriteStore: (overwrite) =>
      set((state) => ({ overwriteStore: overwrite })),
    menuItems: [],
    setMenuItems: (menu) => set((state) => ({ menuItems: menu })),
    removeCategoryByName: (categoryName) =>
      set((state) => ({
        menuItems: state.menuItems.filter(
          (category) => category.category !== categoryName
        ),
      })),
    error: null,
    setError: (err) => set((state) => ({ error: err })),
  };
});

export const useImageStore = create((set) => {
  return {
    imagePaths: [],
    imageFiles: [],
    croppedImages: [],
    addNewImageFile: (file) =>
      set((state) => ({ imageFiles: [...state.imageFiles, file] })),
    addNewImagePath: (path) =>
      set((state) => ({ imagePaths: [...state.imagePaths, path] })),
    deleteImage: (index) =>
      set((state) => ({
        imageFiles: state.imageFiles.filter((_, i) => i !== index),
        imagePaths: state.imagePaths.filter((_, i) => i !== index),
      })),
    removeAllImages: () =>
      set((state) => ({
        imageFiles: [],
        imagePaths: [],
        croppedImages: [],
      })),
    setCroppedImages: (images) => set((state) => ({ croppedImages: images })),
  };
});
