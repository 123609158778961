export const STEP_PAGES = {
  IMAGE_UPLOADER: 1,
  MENU_REVIEW: 2,
  IMAGE_EDITOR: 3,
  RESULT: 4,
};

export const ENV_CONFIG = {
  REDIRECT_URL: process.env.REACT_APP_REDIRECT_URL,
  API_URL: process.env.REACT_APP_API_URL,
  CAN_SELECT_LLM_MODEL: process.env.REACT_APP_CAN_SELECT_LLM_MODEL === "true",
  DEFAULT_MODEL: process.env.REACT_APP_DEFAULT_MODEL ?? "gpt-4o-2024-08-06",
  AVAILABLE_AI_MODELS: process.env.REACT_APP_AVAILABLE_AI_MODELS?.split(
    ","
  ) ?? ["gpt-4o-2024-08-06", "gpt-4o", "gpt-4o-mini", "gpt-4-vision-preview","gpt-4-vision","llama3.2-vision"],
};
